// moved from /app/assets/javascripts/_ajax.js

window.Ajax = (function() {
  var download, download_html, upload;

  class Ajax {
    static get(url) {
      return download('GET', url);
    }

    static get_html(url) {
      return download_html('GET', url);
    }

    static delete(url) {
      return download('DELETE', url);
    }

    static post(url, data) {
      return upload('POST', url, data);
    }

    static put(url, data) {
      return upload('PUT', url, data);
    }

    static patch(url, data) {
      return upload('PATCH', url, data);
    }

  };

  download = function(method, url) {
    var options;
    options = {
      method: method,
      credentials: 'same-origin',
      headers: {
        'Accept': 'application/json'
      }
    };
    return window.fetch(url, options).then(function(response) {
      return response.json();
    });
  };

  download_html = function(method, url) {
    var options;
    options = {
      method: method,
      credentials: 'same-origin',
      headers: {
        'Accept': 'text/html',
        'X-CSRF-Token': document.querySelector('meta[name="csrf-token"]').getAttribute('content')
      }
    };
    return window.fetch(url, options).then(function(response) {
      return response.text();
    }).catch(function(error) {
      return console.log('error:', error);
    });
  };

  upload = function(method, url, data) {
    var options;
    options = {
      method: method,
      credentials: 'same-origin',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'X-CSRF-Token': document.querySelector('meta[name="csrf-token"]').getAttribute('content')
      },
      body: JSON.stringify(data)
    };
    return window.fetch(url, options).then(function(response) {
      return response.json();
    });
  };

  return Ajax;

}).call(this);
