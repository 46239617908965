// mobile nav
document.addEventListener('turbolinks:load', function(e) {
  "use strict";

  // expand/collapse dropdown menus in mobile hamburger
  const menuIds = ['#who-we-are', '#education', '#libraries', '#members', '#location-menu', '#programmes', '#account', '#admin-dashboard', '#admin-settings'];
  document.querySelectorAll(menuIds).forEach((menuItem) => {
    const submenuItem = document.querySelector(`[data-parent="${menuItem.id}"]`);

    menuItem.addEventListener('click', function (e) {
      e.preventDefault();
      menuItem.classList.toggle('open');
      submenuItem.classList.toggle('open');

      document.querySelectorAll(`#navigation .horizontal-dropdown:not([data-parent="${menuItem.id}"])`).forEach((item) => {
        item.classList.remove('open');
      });
    });
  });

  // click mobile hamburger
  var main_menu_toggle_el = document.getElementById('menu-toggle');
  var topbar_el = document.getElementById('navigation');
  main_menu_toggle_el.addEventListener('click', function (e) {
    e.preventDefault();
    topbar_el.classList.toggle('open');
  });
});
