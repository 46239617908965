// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

import Rails from "@rails/ujs"
import Turbolinks from "turbolinks"
//import * as ActiveStorage from "@rails/activestorage"
//import "channels"

Rails.start()
Turbolinks.start()
//ActiveStorage.start()

import "core-js/stable";
import "regenerator-runtime/runtime";

// css
import "../styles/application";

// images
const images = require.context("images", true);
const imagePath = (name) => images(name, true);

// deps
import $ from 'jquery'
import { Foundation } from 'foundation-sites'
document.addEventListener('turbolinks:load', () => $(document).foundation())
import crs from "country-region-selector";

// libs
import "../lib/fontawesome";
import "../lib/ajax";
import "../lib/ajax_dialog";
import "../lib/header_navigation";

// stimulus controllers
import "../controllers";

// init country-region-selector select fields
document.addEventListener('turbolinks:load', function(e) {
  crs.init();
});
