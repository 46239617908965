// This controller handles launching and lazy loading the mapbox modal on events

// TODO might not need this polyfill anymore - all modern browsers support dialog now
import dialogPolyfill from 'dialog-polyfill'

import { Controller } from "stimulus";
import { fire } from "utils/events";

export default class extends Controller {
  static targets = [
    "dialog",
    "map"
  ];

  launch(event) {
    event.preventDefault();

    dialogPolyfill.registerDialog(this.dialogTarget);
    this.dialogTarget.showModal();

    // tell the map controller inside the dialog it can now lazy load
    fire(this.mapTarget, "map:load");
  }

  close(event) {
    event.preventDefault();
    this.dialogTarget.close();
  }
}
