// This controller renders a mapbox map with a single marker at the supplied lat/lng
// Used to show the location for an event

import mapboxgl from '!mapbox-gl';
import 'mapbox-gl/dist/mapbox-gl.css';
//import mapMarkerImg from 'images/map_marker.svg' // Just use the built in marker

import { Controller } from "stimulus";

mapboxgl.accessToken = 'pk.eyJ1IjoibHVrZXAiLCJhIjoiY2o4dXY4Yms5MTY1YjJxcDZ1YmQwd3hxMyJ9.MR1I3XYQn55AmgxlrdtQ-w';

export default class extends Controller {
  static values = {
    latitude: Number,
    longitude: Number,
    zoom: Number,
  }

  connect() {
    this.mapLoaded = false;
    this.element.addEventListener("map:load", this.lazyLoadMap.bind(this));
  }

  disconnect() {
    this.element.removeEventListener("map:load", this.lazyLoadMap.bind(this));

    if (this.mapInstance !== undefined) {
      this.mapInstance.remove();
      this.mapInstance = undefined;
    }
  }

  lazyLoadMap(event) {
    event.preventDefault();

    if (this.mapLoaded) {
      // console.log('map already loaded, exit');
      return;
    }

    this.mapInstance = new mapboxgl.Map({
      container: this.element,
      style: 'mapbox://styles/mapbox/streets-v12', // stylesheet location
      center: [
        // starting position
        this.lng,
        this.lat
      ],
      zoom: this.zoomValue // 18 starting zoom
    });

    // add zoom controls
    this.mapInstance.addControl(new mapboxgl.NavigationControl({ showCompass: false }));

    this.__marker = new mapboxgl.Marker({
      draggable: false,
      color: "green"
    });

    let marker = this.__marker;
    marker.setLngLat([ this.lng, this.lat ]);
    marker.addTo(this.mapInstance);

    // Just use the built in marker
    // // create an element for the marker
    // let markerDiv = document.createElement('div');
    // markerDiv.className = 'marker';
    // markerDiv.style.backgroundImage = `url('${mapMarkerImg}')`;
    // markerDiv.style.width = '27px';
    // markerDiv.style.height = '40px';

    // // add marker to map
    // new mapboxgl.Marker(markerDiv)
    //   .setLngLat([ this.longitudeValue, this.latitudeValue ])
    //   .addTo(this.mapInstance);

    this.mapLoaded = true;
  }

  get lat() {
    return this.latitudeValue || -24; // default to middle of AU
  }

  get lng() {
    return this.longitudeValue || 134; // default to middle of AU
  }
}
