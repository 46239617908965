import { Controller } from "stimulus";
import { loadStripe } from "@stripe/stripe-js/pure";
import { removeNode, strToEl } from "utils/manipulation";

const style = {
  base: {
    color: "#666",
    fontFamily: "Helvetica Neue, Helvetica, Arial, sans-serif",
    "::placeholder": {
      color: "#aaaaaa",
    },
  },
  invalid: {
    color: "#c75454",
    iconColor: "#c75454",
  },
};

export default class extends Controller {
  static targets = ["card", "error", "errors", "sourceField", "form", "spinner"];

  static values = {
    publishableKey: String,
  };

  connect() {
    this.hasCardTarget && this.load();
  }

  disconnect() {
    this.card.destroy();
    this.card = null;
  }

  onSubmit(event) {
    event.preventDefault();
    this.submit();
  }

  async load() {
    this.stripe = await loadStripe(this.publishableKeyValue);

    const elements = this.stripe.elements({});

    this.card = elements.create("card", { style, hidePostalCode: true });
    this.card.mount(this.cardTarget);
    this.card.addEventListener("change", this.onChange.bind(this));
  }

  submit() {
    this.clearErrors();

    const params = {};

    this.spinnerTarget.classList.remove('d-none');

    return this.stripe
      .createSource(this.card, params)
      .then(this.onComplete.bind(this));
  }

  onComplete(result) {
    this.clearErrors();

    if (result.error) {
      this.onError(result);
    } else {
      this.onSuccess(result);
    }

    return result;
  }

  onSuccess(result) {
    this.sourceFieldTarget.value = result.source.id;
    this.formTarget.submit();
  }

  onError(result) {
    this.spinnerTarget.classList.add('d-none');
    this.addError(result.error.message);
  }

  onChange(result) {
    if (result.error) {
      this.onError(result);
    } else {
      this.clearErrors();
    }
  }

  addError(msg) {
    const error = strToEl(`
      <div class="text-danger" data-${this.identifier}-target="error">
        ${msg}
      </div>`);

    this.errorsTarget.appendChild(error);
    this.toggleErrors();

    return error;
  }

  toggleErrors() {
    this.errorsTarget.classList.toggle("d-none", !this.hasErrorTarget);
  }

  clearErrors() {
    this.errorTargets.forEach((target) => removeNode(target));
    this.toggleErrors();
  }
}
