// moved from inline js
// /app/views/shared/_ajax_dialog.html.slim
// /app/views/admin/shared/_ajax_dialog.html.slim

// TODO could use a refactor

// TODO might not need this polyfill anymore - all modern browsers support dialog now
import dialogPolyfill from 'dialog-polyfill'

document.addEventListener('turbolinks:load', function(e) {
  var dialog_el = document.getElementById('dialog');
  if (!dialog_el) return;

  dialogPolyfill.registerDialog(dialog_el);

  var event = document.createEvent('Event');
  event.initEvent('ajax_dialog:update', true, true);

  document.addEventListener('click', function (e) {
    if (e.target.matches('[data-dialog-href], [data-dialog-href] *')) {
      e.preventDefault();
      openAjaxDialogUrl(e.target.closest('[data-dialog-href]').dataset.dialogHref);
    } else if (e.target.matches('#dialog .close')) {
      e.preventDefault();
      dialog_el.close();
    }
  });
});

function openAjaxDialogUrl(url) {
  var dialog_el = document.getElementById('dialog');
  if (!dialog_el) return;
  dialogPolyfill.registerDialog(dialog_el);

  var event = document.createEvent('Event');
  event.initEvent('ajax_dialog:update', true, true);

  window.Ajax.get_html(url).then(function (data) {
    dialog_el.innerHTML = data;
    if (!dialog_el.open) {
      dialog_el.showModal();
    }

    dialog_el.dispatchEvent(event);
  });
}

window.openAjaxDialogUrl = openAjaxDialogUrl;
